// /* You can add global styles to this file, and also import other style files */
// @import "../node_modules/bootstrap/scss/mixins";
// @import "~bootstrap/dist/css/bootstrap.css";
// @import './assets/styles/variables';

// * 
// {
//     font-family: 'Roboto', sans-serif;
//     font-style: normal;
// }

body 
{
    font-family: "Raleway", "Helvetica", "Arial", "sans-serif"!important;
}

$color_black: #000000;
$color_white: #ffffff;
$color_grey: #C4C4C4;
$color_grey1: #A9A6A6;
$color_grey2:  #E5E5E58A;
$color_grey3: #545252;
$color_grey4: rgba(0, 0, 0, 0.25);
$color_grey5: #656363;
$color_grey6: rgba(150, 150, 150, 0.25);
$color_grey7: rgba(196, 196, 196, 0.29);
$color_grey8: #4B4A4A;
$color_grey9: #FFF8F8;
$color_grey10: rgba(196, 196, 196, 0.73);
$color_grey11: #4F4E4E;
$color_grey12: #C4C4C442;
$color_grey13: #C4C4C44A;
$color_grey14: rgba(217, 217, 217, 0.33);
$color_grey15: #CCCCCC;
$color_blue: rgba(24, 135, 215, 0.87);
$color_blue1: rgba(9, 118, 98, 0.81);
$color_blue2: #1887D7;
$color_blue3: #063159E0;
$color_blue4: rgba(6, 49, 89, 0.28);
$color_blue5: #063159;
$color_blue6: rgba(108, 177, 226, 0.31);
$color_blue7: rgb(173, 216, 230);
$color_blue8: rgba(6, 49, 89, 0.88);
$color_blue9: #3F8CB8;
$color_blue10: rgba(6, 54, 99, 0.78);
$color_green: #29A908;
$color_red: #E71414;
$color_yellow: #FFC90A;